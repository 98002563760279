<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 客户名称 -->
      <FormItem>
        <span>文件名称：</span>
        <Input clearable v-model="queryFrom.title" class="iviewIptWidth250"></Input>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="Model2 = true">新增</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :pages="pages" :isLoad="isLoad" :loading="isLoad" totalText="条记录" :total="total" @change-page="changePage"> </Table>
    <!-- 通知公告弹窗 -->
    <Modal v-model="Model2" title="新增文件" width="1000" :closable="false" :mask-closable="false">
      <div class="noticeTitle flexS">
        <span>标题：</span>
        <Input maxlength="100" v-model="step.title" style="width: 100%; flex: 1;"></Input>
      </div>
      <div class="noticeTitle flexS">
        <span>文件：</span>

        <div class="infoContent flex1">
          <Spin fix v-if="loading">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
            <div>上传中...</div>
          </Spin>
          <Input v-if="!loading" style="width: 100%;" placeholder="请选择上传文件" readonly v-model="fileName.file_name">
            <span slot="append" class="colorGrey">
              <Upload id="iviewUp" :format="formatList" :on-format-error="onFormatError" :show-upload-list="false" :on-progress="onProgress" :on-success="handleSuccessQuill" :on-error="handleErrorQuill" :headers="header" name="richTextAccessory" :max-size="10000" :action="url">
                <span>上传</span>
              </Upload>
            </span>
          </Input>
        </div>
      </div>
      <span class="redSpan">可上传Word文档、 Excel文档、 pdf文档及 zip、rar压缩包 </span>
      <br />
      <span class="redSpan">若有照片和TXT文档，请压缩后上传</span>
      <div slot="footer" class="marginTop">
        <span class="pageBtn finger btnReset" @click="cancel"> 取消 </span>
        <span class="pageBtn finger btnSure marginLeft20" @click="newConfirm"> 确定 </span>
      </div>
    </Modal>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="Model3" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否删除此文件？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="deleteNotice">确定</span>
          <span class="pageBtn finger btnCancle" @click="Model3 = false">取消</span>
        </div>
      </div>
    </Modal>

    <a id="aLink" :href="href" download="download" target="_blank" style="display: none;"></a>
  </div>
</template>

<script>
import Table from '@/components/table'
import http from '@/service/baseUrl.js'
import FormUpload from '@/components/formUpload'
export default {
  components: {
    Table,
    FormUpload,
  },
  data() {
    return {
      formatList: ['xml', 'dotm', 'docm', 'dotx', 'rtf', 'dot', 'wpt', 'wps', 'docx', 'xlt', 'xlsm', 'csv', 'dif', 'xla', 'xlsx', 'rar', 'pdf', 'zip', 'rtf', 'xls', 'doc'],
      loading: false,
      downloadTitle: '',
      href: '',
      Model1: false,
      Model2: false,
      Model3: false,
      announcement_detail_id: '',
      step: {
        title: '',
        content: '',
      },
      NoticeInfo: {},
      queryFrom: {
        title: '',
        page: 1,
        rows: 10,
        content: '',
      },
      listData: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 90,
        },
        {
          title: '标题名',
          key: 'title',
          align: 'center',
        },
        {
          title: '创建人',
          key: 'user_name',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'create_time',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            return (
              h,
              'div',
              [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                      marginRight: '40px',
                    },
                    on: {
                      click: () => {
                        // this.Model1 = true
                        this.looknotic(param.row)
                      },
                    },
                  },
                  '查看'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.Model3 = true
                        this.announcement_id = param.row.announcement_id
                      },
                    },
                  },
                  '删除'
                ),
              ]
            )
          },
        },
      ],
      pages: {
        page: 1,
        rows: 10,
      },
      isLoad: true,
      total: 0,
      url: '',
      header: '',
      fileName: {},
    }
  },
  created() {
    this.query()
    this.url = http.http + '/upload_file'
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem('adminohoqicbi=='),
    }
  },
  methods: {
    // 上传失败
    handleErrorQuill() {
      this.loading = false
    },
    // 取消上传
    cancel() {
      this.Model2 = false
      this.fileName = ''
      this.step.title = ''
    },
    getKey(key, name, url) {
      this.$nextTick(() => {
        this.fileName.file_name = name
        this.$forceUpdate()
      })
      console.log(name, this.fileName.file_name)
    },
    // 上传时
    onProgress() {
      this.loading = true
    },
    // 文件清除
    FileChange(e) {
      if (!e) {
        this.fileName = this.$options.data().fileName
      }
    },
    // 删除通知公告
    deleteNotice() {
      this.$http.DeleteAll(this.$api.FileManage, { announcement_id: this.announcement_id }, true).then(res => {
        this.Model3 = false
        this.$Message.success('删除成功')
        this.query()
      })
    },
    // 确认上传文件
    newConfirm() {
      if (!this.step.title) {
        this.$Message.warning('请填文件标题')
        return
      }
      if (!this.fileName) {
        this.$Message.warning('请选择文件再上传')
        return
      }
      let fileStr = JSON.stringify(this.fileName).replace(/\\/gi, '')
      let obj = {
        title: this.step.title,
        file_content: fileStr,
      }
      this.$http.post(this.$api.FileManage, obj, true).then(res => {
        this.$Message.success('上传成功')
        this.loading = false
        this.step.title = ''
        this.fileName = ''
        this.query()
        this.Model2 = false
      })
    },
    // 上传格式验证
    onFormatError(file, fileList) {
      console.log(file, fileList)
      this.$Message.error('请上传Word文档、 Excel文档、 pdf文档及 zip、rar压缩包类文件')
      this.loading = false
    },
    handleSuccessQuill(res) {
      console.log(res, '数组')
      this.loading = false
      this.fileName = res.data
    },
    //   分页
    changePage(e) {
      this.pages.page = e
      this.query()
    },
    //   查询
    query() {
      let obj = {
        title: this.queryFrom.title,
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.isLoad = true
      this.$http.get(this.$api.FileManage, obj, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.listData.forEach(item => {
          item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD') : ''
        })
      })
    },
    //   查看通知
    looknotic(item) {
      window.open(item.file_url, '')
      //   let alink = document.getElementById('aLink')
      //   this.href = item.file_url
      //   this.downloadTitle = item.title
      //   alink.click()
      //   const alink = document.createElement('a')
      //   alink.href = item.file_url
      //   alink.download = item.title
      //   alink.click()
    },
  },
}
</script>

<style lang="less" scoped>
.marginLeft20 {
  margin-left: 20px;
}
.noticeTitle {
  width: 100%;
  margin: 15px 0px;
  //    display: flex;
  //    align-items: center;
  height: auto;
  span:nth-child(1) {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
  }
  .colorGrey {
    font-weight: 400 !important;
    cursor: pointer;
  }
  span:nth-child(2) {
    font-size: 16px;
    color: #333333;
  }
  .infoContent {
    width: 100%;
    p {
      font-size: 16px;
      color: #333333;
      line-height: 40px;
      text-indent: 32px;
    }
    .upfile {
      width: 100%;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      display: flex;
      align-items: center;

      .btn {
        width: 80px;
        height: auto;
        text-align: center;
        background-color: #f8f8f9;
        line-height: 40px;
        border-left: 1px solid #dcdee2;
        cursor: pointer;
      }
    }
  }
}
.marginTop {
  margin-top: 50px;
}
.flexS {
  display: flex;
  align-items: center;
}
.flex1 {
  flex: 1;
  position: relative;
}
.fontw {
  font-weight: 400 !important;
}
.upfile /deep/ .ivu-input {
  border: 1px solid white !important;
}
.redSpan {
  color: red;
  font-size: 12px;
  margin-left: 60px;
}
</style>
